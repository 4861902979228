import { filter } from "lodash";
import { useEffect, useState } from "react";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from "../../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../../sections/@dashboard/user";
import { useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import Page from "../../../components/Page";
import { BorderAllRounded, Sync } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
// for Dialog box
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import axios from "axios"; // for axios request

import "./students.css"; //Custom CSS
import { CSVLink } from "react-csv"; // for CSV file download
// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function maskPhoneNumber(phoneNumber) {
  if (!phoneNumber || phoneNumber.length !== 10) {
    return "Invalid phone number";
  }

  const firstTwoDigits = phoneNumber.slice(0, 2);
  const lastTwoDigits = phoneNumber.slice(-2);
  const maskedDigits = "xxxxx";

  return `${firstTwoDigits}${maskedDigits}${lastTwoDigits}`;
}

function maskEmail(email) {
  if (!email) {
    return "Invalid email";
  }

  const [username, domain] = email.split('@');
  const maskedUsername = username.slice(0, 3) + 'xxx' + username.slice(-3);

  return `${maskedUsername}@${domain}`;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      // console.log("User ",_user)
      const studentNameMatch = _user?.StudentName?.toLowerCase()?.includes(
        query?.toLowerCase()
      );
      const emailMatch = _user?.email
        ?.toLowerCase()
        ?.includes(query?.toLowerCase());
      const phoneMatch = String(_user?.mobile_no)
        // ?.toLowerCase()
        ?.includes(query);

      return studentNameMatch || emailMatch || phoneMatch;
    });
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function StudentList() {
  // Fetching role and access..
  const detailObject = JSON.parse(localStorage.getItem("detailObject"));
  const accessArr = detailObject?.access_level?.split(',');
  const role = localStorage.getItem('role');


  const shouldShowUnassigned = accessArr?.includes('delete') || role === 'Admin'; 

  const TABLE_HEAD = [
    // { id: 'select', label: 'Select', alignRight: false },
    { id: "", label: "S.No", alignRight: false },
    { id: "StudentName", label: "Name", alignRight: false },
    // { id: "Enrollment", label: "Enrollment No.", alignRight: false },
    { id: "mobileNumber", label: "Phone No.", alignRight: false },
    { id: "email", label: "Email", alignRight: false },
    // { id: "userfrom", label: "User From", alignRight: false },
    { id: "coupon", label: "Coupon", alignRight: false },
    { id: "charges", label: "Charges", alignRight: false },
    { id: "assignBy", label: "Assign By", alignRight: false }, // Changed the key to be unique
    { id: "view", label: "View", alignRight: false },
    ...(shouldShowUnassigned ? [{ id: "unassigned", label: "Unassigned", alignRight: false }] : []),
  ];

  const [deleteConfirm, setDeleteConfirm] = useState(null);
  // Code for handling Dialog box
  const [showDownload, setShowDownload] = useState(false);
  const [download, setDownload] = useState(true);
  const [statusUser, setStatusUser] = useState();
  const [statusStaus, setStatusStatus] = useState();
  const [errorOTP, setErrorOtp] = useState();
  const [open1, setOpen1] = useState(false);
  const [open3, setOpen3] = useState(false);


  const handleClickOpen3 = (index) => {
    setDeleteConfirm(index);
    setOpen3(true);
  };

  const handleClose3 = () => {
    setDeleteConfirm(null);
    setOpen3(false);
  };

  const handleConfirm3 = (id) => {
    removeStudent(id);
    handleClose3();
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const sendOtp = () => {
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      action: "Exporting All Users Data",
    };

    // axios
    //   .post(
    //     `${process.env.REACT_APP_LIVE_URL}/adminTeacher/send-otp`,
    //     data,
    //     config
    //   )
    //   .then((response) => { })
    //   .catch((e) => {
    //     //console.log(e);
    //   });
  };
  const verifyOtp = () => {
    //console.log(document.getElementById('name').value);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      otp: document.getElementById("name").value,
    };


  };

  // For handling Dialong box
  const [allStudents, setAllStudents] = useState([]);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [filterStudent, setFilterStudent] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target?.value, 10));
    setPage1(0);
  };

  const handleCheck = (event, id) => {
    const selectedIndex1 = selectedStudent?.indexOf(id);
    let newSelected = [];
    if (selectedIndex1 === -1) {
      newSelected = newSelected.concat(selectedStudent, id);
    } else if (selectedIndex1 === 0) {
      newSelected = newSelected.concat(selectedStudent.slice(1));
    } else if (selectedIndex1 === selected.length - 1) {
      newSelected = newSelected.concat(selectedStudent.slice(0, -1));
    } else if (selectedIndex1 > 0) {
      newSelected = newSelected.concat(
        selectedStudent.slice(0, selectedIndex1),
        selectedStudent.slice(selectedIndex1 + 1)
      );
    }
    console.log("Selected ",newSelected)
    setSelectedStudent(newSelected);
  };

  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState([]);
  const [courseName, setCourseName] = useState("")
  const [loading, setLoading] = useState(true);
  const [ids, setIds] = useState([])
  // for Dialong box All Students
  const filteredStudents = applySortFilter(
    allStudents ? allStudents : ["no"],
    getComparator(order, orderBy),
    filterStudent
  );
  const handleFilterStudentByName = (event) => {
    setFilterStudent(event.target?.value);
  };

  //Batch Id
  const { batchId, active } = useParams();

  //Getting Details
  const token = localStorage.getItem("token");
  async function fetchUsers() {
    const fullResponse = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-enrolled-student-by-course-id/${batchId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseJson = await fullResponse.json();
    usersSet(responseJson?.data);
    setIds(() => {
      return responseJson?.data?.map((std) => std.id) || [];
    });
    setCourseName(responseJson?.course?.name);
    setLoading(false);
     toast.success("Student Fetched Successfully of Course ")
  }

  useEffect(() => {
   
    fetchUsers();
  }, []);

  // getting all student

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchStudents() {
      setLoading(true)
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-user-list?page=${page1}&limit=${rowsPerPage1}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      //console.log(responseJson?.data);
      setAllStudents(responseJson?.data?.data);
      setTotal(responseJson?.data?.total)
      setAllStudents((prevStudents) =>
        prevStudents.filter((std) => !ids.includes(std.id))
      );

      setLoading(false);
      if (responseJson?.msg === "not an admin") {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("details");
        navigate("/");
      }
    }

    fetchStudents();
  }, [rowsPerPage1, page1, ids]);

    console.log("Selected ",selectedStudent);

  // Assigning to Student

  const handleAssign = () => {
    const token = localStorage.getItem("token");
    const authToken = token;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const data = {
      "students": selectedStudent,
      "course_id": batchId,
    };

    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/assign-course`,
        data,
        config
      )
      .then((response) => {
        //console.log(response);
        toast.success("Course Assigned Successfully")
        // toast.success(response?.data?.msg, { autoClose: 3000 });
        fetchUsers();
        handleClose();
      })
      .catch((e) => {
        toast.error(e, { autoClose: false });
        handleClose();
        //console.log(e);
      });
    setSelectedStudent([]);
    // setTimeout(() => window.location.reload(), 3000);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target?.checked) {
      const newSelecteds = users?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target?.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target?.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound =
    filteredUsers?.length === 0 || filteredStudents[0] == "no";

  const removeStudent = async (userId) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/remove-student-from-course`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            student_id: userId,
            course_id: batchId,
          }),
        }
      );

      if (!response?.ok) {
        throw new Error("Network response was not ok");
      }

      toast.success("Student Removed Successfully");
      fetchUsers();
      // setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
      toast.success(error);
    }
  };

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader color="#8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Students">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Typography variant="h3" gutterBottom>
                All Users Of the Course / {courseName}
              </Typography>
              {
                (role == 'Admin' || accessArr?.includes('write')) && <Button
                  variant="contained"
                  disabled={active == "0" ? true : false}
                  className="button1"
                  sx={{
                    width: "20%",
                    alignSelf: "center",
                    color: "black",
                    background: "#e6e6ff",
                    "&:hover": { background: "#8080ff" },
                    BorderAllRounded: "10px",
                  }}
                  onClick={handleClickOpen}
                >
                  Add Students <br />
                  {/* <span style={{ fontSize: '10px' }}> {active == '0' ? '(Batch Not Active)' : ''}</span> */}
                </Button>
              }

            </Stack>

            {/* code for dialog box */}
            <>
              <Dialog
                open={open}
                onClose={handleClose}
                className="assign_dialog"
              >
                <AppBar sx={{ position: "relative", background: "#e6e6ff" }}>
                  <Toolbar style={{ width: "100%" }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: "100%", color: "black" }}
                    >
                      <DialogTitle>Assign Students</DialogTitle>
                      <Stack direction="row" justifyContent="space-between">
                        <UserListToolbar
                          numSelected={selectedStudent?.length}
                          filterName={filterStudent}
                          onFilterName={handleFilterStudentByName}
                          from="AssignStudent"
                        />
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={handleClose}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Toolbar>
                </AppBar>
                <DialogContent>
                  <Card>
                    <Scrollbar>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Selected</TableCell>
                              <TableCell align="left">Student Name</TableCell>
                              <TableCell align="left">Mobile No.</TableCell>
                              <TableCell align="left">Email</TableCell>
                              {/* <TableCell align="left">Username</TableCell> */}
                            </TableRow>
                          </TableHead>
                         {loading? <SyncLoader color="#8080ff" loading={loading} size={20} />: <TableBody>
                            {filteredStudents
                              
                              ?.map((row, index) => {
                                const {
                                  id,
                                  _id,
                                  StudentName,
                                  email,
                                  username,
                                  mobileNumber,
                                  profilePhoto,
                                  userId,
                                } = row;
                                const isItemSelected1 =
                                  selectedStudent.indexOf(id) !== -1;
                                // row.sno = index + 1 + rowsPerPage1 * page1
                                return (
                                  <TableRow
                                    hover
                                    key={index + 1}
                                    tabIndex={-1}
                                    role="checkbox"
                                    selected={isItemSelected1}
                                    aria-checked={isItemSelected1}
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        checked={isItemSelected1}
                                        onChange={(event) => {
                                          handleCheck(event, id);
                                        }}
                                      />
                                    </TableCell>
                                    {/* <TableCell >
                                                                <Typography variant="subtitle2" noWrap>
                                                                    {row.sno}
                                                                </Typography>

                                                            </TableCell> */}

                                    <TableCell
                                      component="th"
                                      scope="row"
                                      pl={2}
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Avatar
                                          alt={StudentName}
                                          src={profilePhoto}
                                          sx={{ width: 28, height: 28 }}
                                        />
                                        <Typography variant="subtitle2" noWrap>
                                          {row.first_name.concat(" ").concat(row.last_name)}
                                        </Typography>
                                      </Stack>
                                    </TableCell>
                                    <TableCell align="left">
                                      {maskPhoneNumber (row?.mobile_no?.toString() ? row?.mobile_no?.toString() : "NA")}
                                    </TableCell>
                                    <TableCell align="left">{maskEmail(email)}</TableCell>
                                    {/* <TableCell align="left">
                                      {username}
                                    </TableCell> */}
                                  </TableRow>
                                );
                              })}
                            {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                          </TableBody>}
                        </Table>
                      </TableContainer>
                    </Scrollbar>
                    <TablePagination
                      rowsPerPageOptions={[10, 25]}
                      component="div"
                      count={total}
                      rowsPerPage={rowsPerPage1}
                      page={page1}
                      onPageChange={handleChangePage1}
                      onRowsPerPageChange={handleChangeRowsPerPage1}
                    />
                  </Card>
                </DialogContent>

                <hr></hr>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  m={3}
                >
                  <Button
                    onClick={handleAssign}
                    variant="contained"
                    className="button2"
                    sx={{
                      width: "20%",
                      alignSelf: "center",
                      background: "#e6e6ff",
                      color: "black",
                      "&:hover": { background: "#8080ff" },
                      BorderAllRounded: "10px",
                    }}
                  >
                    Assign
                  </Button>
                </Stack>
              </Dialog>
            </>

            <Card>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
                sx={{ margin: "10px" }}
              >
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                {/* <ExportCSV csvData={users} fileName='Student Data' /> */}
                {/* <Button
                  variant="outlined"
                  onClick={() => {
                    handleClickOpen1();
                    sendOtp();
                    setShowDownload(true);
                  }}
                >
                  Export
                </Button> */}
              </Stack>



              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected?.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row, index) => {
                          const {
                            id,
                            _id,
                            StudentName,
                            email,
                            username,
                            mobileNumber,
                            profilePhoto,
                            userId,
                          } = row;
                          const isItemSelected =
                            selected.indexOf(StudentName) !== -1;
                          // row.sno = index + 1 + rowsPerPage * page
                          // console.log(row);
                          return (
                            <TableRow
                              hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, -+-++-+)} />
                                                    </TableCell> */}
                              <TableCell>
                                <Typography variant="subtitle2" noWrap>
                                  {/* {row?.sno} */}
                                  {index + 1 + rowsPerPage * page}
                                </Typography>
                              </TableCell>



                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Avatar
                                    alt={StudentName}
                                    src={profilePhoto}
                                    sx={{ width: 28, height: 28 }}
                                  />
                                  <Typography noWrap>
                                    {row?.first_name?.concat(" ").concat(row?.last_name)}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              {/* <TableCell>
                                <Typography variant="subtitle2" noWrap>
                                  {row.id}
                                </Typography>
                              </TableCell> */}
                              <TableCell align="left">
                                {row.mobile_no ? row.mobile_no : "NA"}
                              </TableCell>
                              <TableCell align="left">{email}</TableCell>
                              <TableCell align="left">{row?.coupon ? row.coupon : "N/A"}</TableCell>
                              <TableCell align="left">{row?.assigned_by == 1 ? "0" : row?.price}</TableCell>
                              <TableCell align="left">{row?.assigned_by ? "Admin" : "User"}</TableCell>

                              {/* <TableCell align="left">{row.user_from}</TableCell> */}
                              <TableCell align="left">
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="error"
                                  onClick={() =>
                                    navigate(
                                      `/dashboard/students/studentsProfile/${id}`
                                    )
                                  }
                                >
                                  view
                                </Button>
                              </TableCell>

                             {shouldShowUnassigned && <TableCell align="left">
                      
                               

                                {
                                  deleteConfirm === index ? (
                                    <>
                                      <Dialog
                                        open={open3}
                                        onClose={handleClose3}
                                      >
                                        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
                                        <DialogContent>
                                          Are you sure you want to unassigned the course to this student?
                                        </DialogContent>
                                        <DialogActions>
                                          <Button onClick={() => handleConfirm3(row.id)} color="error">
                                            YES
                                          </Button>
                                          <Button onClick={handleClose3} color="primary">
                                            NO
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                    </>
                                  ) : (
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      color="error"
                                      onClick={() => { handleClickOpen3(index) }}
                                    >
                                      Unassigned
                                    </Button>
                                  )
                                }
                              </TableCell>}
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
            <ToastContainer
              position="bottom-center"
              autoClose={1000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </Container>
        </Page>
      )}
    </>
  );
}
