import React from "react";
import * as Yup from "yup";
import { useContext, useState, useEffect } from "react";
import { useNavigate, useResolvedPath } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getGroups } from "../../../utils";

// components
import Iconify from "../../../components/Iconify";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";

import { User } from "../../../context/context";
import Page from "../../../components/Page";
import { useTheme } from "@mui/material/styles";
import axios from "axios";

import "./test.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getFileUrl } from "../../../utils/firebaseUtils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// function getStyles(name, teacher, theme) {
//     return {
//         fontWeight:
//             teacher.indexOf(name) === -1
//                 ? theme.typography.fontWeightRegular
//                 : theme.typography.fontWeightMedium,
//     };
// }
const AddTestSeries = () => {
  const navigate = useNavigate();
  // const { setRole } = useContext(User)

  // const [category, setCategory] = React.useState('');
  // const [type, setType] = React.useState('');
  const [users, usersSet] = React.useState([]);
  const [loading, setLoading] = useState(true);

  const [subject, setSubject] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [states, setStates] = useState();
  const [boards, setBoards] = useState();
  const [classVal, setClass] = useState(null);
  const [centralExam, setCentralExam] = useState(null);
  const [stateExam, setStateExam] = useState(null);
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);

  const [bannerUrl, setBannerUrl] = useState();
   const [lifetimeAccess, setLifetimeAccess] = useState(false);


  const [selectedState, setSelectedState] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);

  const [pdfFocus, setPdfFocused] = useState(false);
  const [pdf, setPdf] = useState();

  const changeHandler2 = async (event) => {
    setPdf(event.target?.files[0]);
    // const url = await getFileUrl(event.target?.files[0]);
    //console.log(file)
  };

  const streamCategory = [
    {
      id: "class",
      title: "School Exams",
    },
    {
      id: "central-exam",
      title: "Central Exams",
    },
    {
      id: "state-exam",
      title: "State Exams",
    },
  ];

  const theme = useTheme();

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    // exam_type: Yup.string().required('Exam type is required'),
    starting_date: Yup.string().required("Starting Date is required"),
    charges: Yup.string().required("charges is required"),
    language: Yup.string().required("language is required"),
    //stream: Yup.string().required('Category is required'),
    // no_of_test: Yup.string().required('No. Of Test is required'),
    Validity: Yup.string().required("Validity is required"),
    description: Yup.string().required("Description is required"),
    // discount: Yup.string().required('Discount is required'),
    // charges: Yup.string().required('Charges is required'),
    is_active: Yup.string().required("Status Required is required"),
  });

  const defaultValues = {
    name: "",
    category: "",
    exam_type: "",
    stream: "",
    // subject: '',
    // teacherEmail: '',
    starting_date: "",
    is_active: "true",
    discount: "0",
    charges: "1",
    description: "",
    language: "",
    remark: "N/A",
    Validity: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const fetchLanguages = async () => {
    const token = localStorage.getItem("token");
    try {
      const languageData = await axios.post(
        `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (languageData?.status !== 200)
        throw new Error("Failed to fetch languages");

      setLanguages(languageData?.data?.data);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  // Fetch languages when the component mounts
  useEffect(() => {
    fetchLanguages();
  }, []);

  //Teacher List
  const [teacher, setteacher] = useState([]);
  const [allteachers, teacherSet] = useState([]);
  const [val, setVal] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      // const fullResponse = await fetch(
      //   `${process.env.REACT_APP_LIVE_URL}/adminTeacher/teacherList`,
      //   {
      //     method: "GET",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      // const responseJson = await fullResponse.json();
      // teacherSet(responseJson.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  // const handleChange = (event) => {
  //     const {
  //         target: { value },
  //     } = event;
  //     setteacher(
  //         // On autofill we get a stringified value.
  //         typeof value === 'string' ? value.split(',') : value,
  //     );
  // };

  //Date
  const [focus, setFocused] = React.useState(false);
  const [focus1, setFocuse1] = React.useState(false);
  const [showStartingDate, setShowStartingDate] = React.useState(false);
  const [showEndingDate, setShowEndingDate] = React.useState(false);

  //Stream

  //Banner
  const [file, setFile] = useState();
  const [banner, setBanner] = useState();
  const [groups, setGroups] = useState([]);
  const [exam_groups_id, setExamGroupsId] = useState("")

  const [upscId, setUpscId] = useState("");
  const [groupId, setGroupId] = useState("");
  const [upscData,setUpscData]=useState("");
  const [msg, setMsg] = useState("");
  const [focusThree, setFocusedThree] = React.useState(false);
  const [showBanner, setShowBanner] = React.useState(false);
  const [showSmallBanner, setShowSmallBanner] = React.useState(false);
  const changeHandler = async (event) => {
    setBannerUrl(await getFileUrl(event.target.files[0]));
    setFile(event.target.files[0]);
  };
  const changeBannerHandler = async (event) => {
    setBanner(await getFileUrl(event.target.files[0]));
 
  };
  
  const changeGroupHandler =(e)=>{
    setExamGroupsId(e.target.value)
  
 }
  //Adding Details
  const onSubmit = async (data) => {
    setLoading(true);
    if (file?.size) {
      const payload = {
        ...data,
        banner_url: bannerUrl,
        group_id:groupId,
        small_banner:banner,
      exam_groups_id: exam_groups_id,

        course: selectedCategory,
        state_exam_id: selectedState,
        board_id: selectedBoard,
        exam_id: (centralExam || stateExam)==3?groupId:(centralExam || stateExam),
        class_id: classVal,
        curriculum: pdf ? await getFileUrl(pdf) : "",
        ending_date: data.ending_date,
        access: lifetimeAccess ? 1 : 0,
      };
      // console.log(payload);

      let token = localStorage.getItem("token");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/create-testseries`,
          payload,
          config
        );
        const responseData = response.data;
        console.log(responseData);
        if (responseData.response_code === 200) {
          toast.success("Test Series Added");
          setShowBanner(false);
          setShowStartingDate(false);
          const testSeriesId = responseData.data.id;
          setTimeout(() => {
            navigate(`/dashboard/test/testSeriesDetails/${testSeriesId}`);
          }, 1000);
          reset();
        } else {
          toast.error("Failed to add test series");
        }
      } catch (error) {
        console.error("Error adding test series:", error);
        toast.error("Failed to add test series");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getGroups();
      setGroups(data)
      try {
        const body={
          "exam_category_slug": "central-exams",
          "exam_group_slug": "upsc"
      };
        const response = await axios.post('https://invictaa.com/app-api/api/frontend/get-exam-group-exams',body);
         console.log("Response  : ",response.data.data)
        setUpscData(response.data.data.exams); // Assuming the response data is an array
      } catch (err) {
        // setError(err.message);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, []);
  const handleCategoryChange = async (event) => {
    const selectedCategoryId = event.target.value;

    console.log(selectedCategoryId);
    console.log(event.target.value);
    setStates(null);
    setBoards(null);
    setSubCategories(null);
    setSelectedBoard(null);
    setSelectedState(null);

    setIsCategoryLoading(true);
    setSelectedCategory(selectedCategoryId);

    const token = localStorage.getItem("token");
    switch (selectedCategoryId) {
      case "central-exam":
        const centralExams = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-central-exams`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSubCategories(centralExams?.data?.data);
        break;

      case "state-exam":
        const stateList = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-states`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(stateList?.data?.data);
        setStates(stateList?.data?.data);
        break;

      case "class":
        const boardsList = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-boards`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(boardsList?.data?.data)
        setBoards(boardsList?.data?.data);
        break;

      default:
        break;
    }
    // Fetch subcategories based on the selected category
    setIsCategoryLoading(false);
  };

  const handleStateChange = async (e) => {
    setIsCategoryLoading(true);
    setSelectedState(e.target.value);
    const currentState = e.target.value;
    setSubCategories(null);
    const stateExamsList = await axios.post(
      `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-state-exams`,
      { state_exam_id: currentState }
    );
    console.log(stateExamsList.data);
    setSubCategories(stateExamsList.data.data);
    setIsCategoryLoading(false);
  };

  const handleBoardChange = async (e) => {
    setIsCategoryLoading(true);
    const currentBoard = e.target.value;
    setSelectedBoard(e.target.value);
    setSubCategories(null);
    const boardExamsList = await axios.post(
      `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-classes`,
      { board_id: currentBoard }
    );
    console.log(boardExamsList.data);
    setSubCategories(boardExamsList.data.data);
    setIsCategoryLoading(false);
  };

  const handleSubCategoryChange = (e) => {
    setClass(null);
    setStateExam(null);
    setCentralExam(null);
    setUpscId(e.target.value);

    if (boards) setClass(e.target.value);
    else if (states) setStateExam(e.target.value);
    else setCentralExam(e.target.value);

    console.log("boards", classVal);
    console.log("states", stateExam);
    console.log("central", centralExam);
  };

   const handleLifetimeAccessChange = (event) => {
     setLifetimeAccess(event.target.checked);
   };

  return (
    <>
      <Page title="Test Series">
        <Stack spacing={3} ml={5}>
          <Typography className="page-heading">Add Test series</Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ width: "80%" }}>
              <Stack spacing={3} direction="row">
                <RHFTextField name="name" label="Test Name " />
              </Stack>

              <Stack direction={"row"} spacing={3}>
                <RHFTextField
                  name="category"
                  label="Category"
                  sx={{ width: "50%" }}
                  select
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                >
                  {streamCategory?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </RHFTextField>

                {states && (
                  <RHFTextField
                    name="states"
                    label="State"
                    sx={{ width: "50%" }}
                    select
                    value={selectedState}
                    onChange={handleStateChange}
                  >
                    {states?.map((state, index) => (
                      <MenuItem key={index} value={state.id}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </RHFTextField>
                )}

                {boards && (
                  <RHFTextField
                    name="boards"
                    label="Boards"
                    sx={{ width: "50%" }}
                    select
                    value={selectedBoard}
                    onChange={handleBoardChange}
                  >
                    {boards?.map((board, index) => (
                      <MenuItem key={index} value={board.id}>
                        {board.name}
                      </MenuItem>
                    ))}
                  </RHFTextField>
                )}

                <RHFTextField
                  name="subCategory"
                  label="Subcategory"
                  sx={{ width: "50%" }}
                  select
                  onChange={handleSubCategoryChange}
                >
               
                  {/* //!This subcategory is working for all categories simultaneously */}

                  {subCategories?.map((subcategory, index) => (
                    <MenuItem key={index} value={subcategory.id}>
                      {subcategory?.name || subcategory?.class_name}
                    </MenuItem>
                  ))}
                </RHFTextField>
                
              </Stack>
             
                {upscId==3&&<Stack>
                <RHFTextField
                  name="Group"
                  label="Group"
                  sx={{ width: "50%" }}
                  select
                  onChange={(e)=>setGroupId(e.target.value)}

                >
               
                  {/* //!This subcategory is working for all categories simultaneously */}

                  {upscData?.map((subcategory, index) => (
                    <MenuItem key={index} value={subcategory.id}>
                      {subcategory?.name }
                    </MenuItem>
                  ))}
                </RHFTextField>
                </Stack>}

          
                {/* <RHFTextField
                name="exam_groups_id"
                label="Exam groups"
                sx={{ width: "50%" }}
                select
                value={exam_groups_id}
                onChange={changeGroupHandler}
              >
                {groups?.map((board, index) => (
                  <MenuItem key={index} value={board.id}>
                    {board.name}
                  </MenuItem>
                ))}
              </RHFTextField> */}
			  
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={lifetimeAccess}
                        onChange={handleLifetimeAccessChange}
                        color="primary"
                      />
                    }
                    label="Lifetime Access"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    <RHFTextField
                      name="starting_date"
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                      InputProps={{
                        classes: {
                          input: "CustomTextField",
                        },
                      }}
                      label="Starting Date"
                      onClick={() => setShowStartingDate(true)}
                      type={showStartingDate || focus ? "date" : "text"}
                    />
                    {!lifetimeAccess && (
                      <RHFTextField
                        name="ending_date"
                        onFocus={() => setFocuse1(true)}
                        onBlur={() => setFocuse1(false)}
                        InputProps={{
                          classes: {
                            input: "CustomTextField",
                          },
                        }}
                        label="Ending Date"
                        onClick={() => setShowEndingDate(true)}
                        type={showEndingDate || focus1 ? "date" : "text"}
                      />
                    )}
                    <Stack direction="column" sx={{ width: "100%" }}>
                      <RHFTextField
                        name="small_file"
                        error={!file && val}
                        onChange={changeHandler}
                 
                        helperText="Image with a size of 712x356 pixels, maintaining a 2:1 aspect ratio (jpeg,jpg,png,webp)"
                        InputProps={{
                          classes: {
                            input: "CustomTextField",
                          },
                        }}
                        label="Banner"
                        onClick={() => setShowBanner(true)}
                        type={showBanner || focusThree ? "file" : "text"}
                      />
                      {/* {!file && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>Banner Requied</Typography>} */}
                      {!file && (
                        <Typography
                          sx={{
                            fontSize: "12px",
                            marginTop: "5px",
                            marginLeft: "10px",
                            color: "red",
                          }}
                        >
                          {msg}
                        </Typography>
                      )}
                    </Stack>
                    <Stack direction="column" sx={{ width: "100%" }}>
                      <RHFTextField
                        name="file"
                        error={!file && val}
                        onChange={changeBannerHandler}
                        // onFocus={() => setFocusedThree(true)}
                        // onBlur={() => setFocusedThree(false)}
                        helperText="Image with a size of 712x356 pixels, maintaining a 2:1 aspect ratio (jpeg,jpg,png,webp)"
                        InputProps={{
                          classes: {
                            input: "CustomTextField",
                          },
                        }}
                        label="Small Banner"
                        onClick={() => setShowSmallBanner(true)}
                        type="file"
                      />
                      {/* {!file && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>Banner Requied</Typography>} */}
                      {!file && (
                        <Typography
                          sx={{
                            fontSize: "12px",
                            marginTop: "5px",
                            marginLeft: "10px",
                            color: "red",
                          }}
                        >
                          {msg}
                        </Typography>
                      )}
                    </Stack>

                    <RHFTextField
                      name="curriculum"
                      onChange={changeHandler2}
                      id="curriculum"
                      label="Curriculum (Optional)"
                      // error={!file && val}
                      sx={{ my: 2 }}
                      // helperText="Pdf Only"
                      onFocus={() => setPdfFocused(true)}
                      onBlur={() => setPdfFocused(false)}
                      // inputProps={{ multiple: true }}
                      InputProps={{
                        classes: {
                          input: "CustomTextField",
                        },
                      }}
                      onClick={() => setPdf(true)}
                      inputProps={{ accept: "application/pdf" }}
                      type={pdf || pdfFocus ? "file" : "text"}
                    />
                  </Stack>
                </Grid>
              </Grid>

              <Stack direction="row" spacing={3}>
                <RHFTextField
                  name="charges"
                  label="Charges"
                  type="number"
                  id="custom-css-outlined-input"
                />
                <RHFTextField
                  name="discount"
                  label="Discount Price"
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                />
                <RHFTextField
                  name="language"
                  label="language"
                  sx={{ width: "100%" }}
                  select
                >
                  {languages.map((language, index) => (
                    <MenuItem key={index} value={language.medium_code}>
                      {language.medium_name}
                    </MenuItem>
                  ))}
                </RHFTextField>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                {/* <RHFTextField name="no_of_test" label="Total no. of tests" type='number' /> */}
                <RHFTextField name="Validity" label="Validity(In Days)" />
                <RHFTextField name="remark" label="Remark" />
                <RHFTextField
                  name="is_active"
                  label="Status"
                  sx={{ width: "100%" }}
                  select
                  type="checkbox"
                >
                  <MenuItem value={"true"}>Publish</MenuItem>
                  <MenuItem value={"false"}>Draft</MenuItem>
                </RHFTextField>
              </Stack>
              <RHFTextEditor name="description" label="Description" />

              {/* <RHFTextField
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                /> */}
            </Stack>

            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <RHFCheckbox name="remember" label="Remember me" />
                <Link variant="subtitle2" underline="hover" sx={{ color: '#e6e6ff' }}>
                    Forgot password?
                </Link>
            </Stack> */}

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                width: "80%",
                marginTop: "5%",
                "&:hover": { background: "#8080ff" },
              }}
              onClick={() => {
                setMsg("Required");
                setVal(true);
                // setMsgDemoVideo('Demo Video Required')
              }}
            >
              Add
            </LoadingButton>
          </FormProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* <Typography sx={{ color: 'red', textAlign: 'center' }}>{fetchMessage}</Typography> */}
        </Stack>
      </Page>
    </>
  );
};

export default AddTestSeries;
