import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import { useParams } from "react-router-dom";
import {
    Card,
    Table,
    Stack,
    Avatar,
    // Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Button,
    Input,
    MenuItem,
    TextField,
    Box,
    Tabs,
    Tab,
    IconButton,
    Menu,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
// mock
// importusers ? from '../_mock/user';
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
//mui icons
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import { ExportCSV } from "../../../utils/ExportCSV";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import MoreVertIcon from "@mui/icons-material/MoreVert";
const token = localStorage.getItem("token");
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    // { id: "sno", label: "S.No", alignRight: false },
    { id: "user_id", label: "UserId", alignRight: false },
    { id: "name", label: "Name", alignRight: false },
    { id: "questionId", label: "Question Id", alignRight: false },

    { id: "course", label: "Course", alignRight: false },

    { id: "exam name", label: "Exam Name / Class", alignRight: false },
    { id: "subject", label: "Subject", alignRight: false },
    { id: "chapter", label: "Chapter", alignRight: false },
    { id: "Feedback Type", label: "Feedback Type", alignRight: false },
    // { id: "Feed", label: "Access", alignRight: false },
    { id: "message", label: "Message", alignRight: false },
    { id: "Date", label: "Date", alignRight: false },
    { id: "view", label: "VIew", alignRight: false },
    { id: "action", label: "Action", alignRight: false },
];



// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


const applySortFilter = (array, comparator, query) => {
    const stabilizedThis = array?.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    if (query) {
        return filter(array, (item) => {
            const name = item?.name?.toLowerCase() || "";
            const couponCode = item?.couponCode?.toLowerCase() || "";
            return (
                name.indexOf(query.toLowerCase()) !== -1 ||
                couponCode.indexOf(query.toLowerCase()) !== -1
            );
        });
    }

    return stabilizedThis?.map((el) => el[0]);
};

// Example comparator function
const comparator = (a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
};





const Question = () => {
    // Code for handling Dialog box
    const { id } = useParams();
    const [download, setDownload] = useState(true);
    const [errorOTP, setErrorOtp] = useState();
    const [searchQuery, setSearchQuery] = useState("");
    const [coupons, setCoupons] = useState([]);


    // Handle search query change
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Get filtered coupons
    const filteredCoupons = applySortFilter(coupons, comparator, searchQuery);

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };



    const [page, setPage] = useState(0);

    const [order, setOrder] = useState("asc");

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState("name");

    const [filterName, setFilterName] = useState("");
    const [selectedId, setSelectedId] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const navigate = useNavigate();
    const [users, usersSet] = useState();
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState();
    const [starting, setStarting] = useState(dayjs());

    //Getting notes
    const [isActiveValue, setIsActiveValue] = useState(false);
    async function fetchUsers() {
        const fullResponse = await fetch(
            `${process.env.REACT_APP_LIVE_URL_NEW}/admin/feedback-by-question/${id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const responseJson = await fullResponse.json();
        usersSet(responseJson.data);
        if (fullResponse.status == 200) {
            toast.success("Feedback fetched Successfully")
        }
        setLoading(false);
    }
    useEffect(() => {
        const token = localStorage.getItem("token");


        fetchUsers();
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = users?.map((n) => n.title);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const filteredUsers = applySortFilter(
        users ? users : ["no"],
        getComparator(order, orderBy),
        filterName
    );

    let helperArray = [];
    filteredUsers?.map((item) =>
        helperArray.push({
            name: item.name,
            coupon_code: item.coupon_code,
            coupon_percentage: item.coupon_percentage,
            start_from: item.start_from,
            end_to: item.end_to,
            status: item.status,
        })
    );

    const isUserNotFound = filteredUsers.length === 0;
    const [isEdit, setIsEdit] = useState(false);
    const [updatedValues, setUpdatedValues] = useState({});



    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);


    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedRow(null);
    };

    const handleDelete = async () => {
        try {

            const response = await fetch(
                `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-feedback/${selectedRow?.id}`,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.ok) {
                toast.success("Feedback deleted successfully.", {
                    position: "bottom-center",
                });

                fetchUsers()
            } else {
                toast.error("Failed to delete Feedback. Please try again.", {
                    position: "bottom-center",
                });
            }
        } catch (error) {
            toast.error("An unexpected error occurred. Please try again.", {
                position: "bottom-center",
            });
            console.error("An error occurred while deleting the Feedback:", error);
        }
        handleMenuClose();
    };

    const viewQuestion = (row) => {
        const url = `${selectedRow?.course == "central-exam" || selectedRow?.course == "state-exam" ? `https://invictaa.com/app-admin/dashboard/exam-question/view/${selectedRow?.questionId}` : `https://invictaa.com/app-admin/dashboard/question/view/${selectedRow?.questionId}`}`;
        window.open(url);
    }
    return (
        <Box sx={{ width: '100%' }}>
            <Container sx={{ padding: "10px 0" }}>
                <Typography variant="h3">
                    Question ({filteredUsers?.length})
                </Typography>
            </Container>
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <UserListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={users?.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                            {filteredUsers
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                ?.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            key={index}
                                            tabIndex={-1}
                                            role="checkbox"
                                         
                                        >
                                            {/* <TableCell>
                                                <Typography variant="subtitle2">
                                                    {index + 1 + rowsPerPage * page}
                                                </Typography>
                                            </TableCell> */}
                                            <TableCell component="th" scope="row" pl={2}>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography variant="subtitle2" noWrap>

                                                        <span>{row?.userId}</span>

                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell component="th" scope="row" pl={2}>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography variant="subtitle2" noWrap>

                                                        <span>{row?.name}</span>

                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell component="th" scope="row" pl={2}>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography variant="subtitle2" noWrap>

                                                        <span>{row?.questionId}</span>

                                                    </Typography>
                                                </Stack>
                                            </TableCell>

                                            <TableCell align="center">
                                                <span>{row?.course}</span>
                                            </TableCell>

                                            <TableCell align="center">
                                                <span>
                                                    {row?.exam_name || row?.class_name || "NA"}
                                                </span>
                                            </TableCell>
                                            <TableCell component="th" scope="row" pl={2}>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography variant="subtitle2" noWrap>
                                                        <span>{row?.subject_name}</span>
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell component="th" scope="row" pl={2}>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography variant="subtitle2" noWrap>
                                                        <span>{row?.chapter_name}</span>
                                                    </Typography>

                                                </Stack>
                                            </TableCell>
                                            <TableCell component="th" scope="row" pl={2}>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography variant="subtitle2" noWrap>
                                                        <span>{row?.feedback_type}</span>

                                                    </Typography>

                                                </Stack>
                                            </TableCell>
                                            <TableCell component="th" scope="row" pl={2}>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography variant="subtitle2" noWrap>
                                                        <span>{row?.message}</span>
                                                    </Typography>

                                                </Stack>
                                            </TableCell>
                                            <TableCell component="th" scope="row" pl={2}>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography variant="subtitle2" noWrap>
                                                        <span>{row?.created_at?.slice(0, 10)}</span>
                                                    </Typography>

                                                </Stack>
                                            </TableCell>



                                            <TableCell>
                                                <Button variant="outlined" size="small" color="primary"
                                                  onClick={(event) => {
                                                        setAnchorEl(event.currentTarget);
                                                        setSelectedRow(row);
                                                        viewQuestion(row);
                                                        console.log("ROw ",row)
                                                    }}
                                                >
                                                    <a 
                                                    
                                                    onClick={()=>{
                                                        const url = `${row?.course == "central-exam" || row?.course == "state-exam" ? `https://invictaa.com/app-admin/dashboard/exam-question/view/${row?.questionId}` : `https://invictaa.com/app-admin/dashboard/question/view/${row?.questionId}`}`;
                                                        window.open(url);
                                                    }}
                                                    style={{ textDecoration: "none", color: "black" }}   >
                                                        View
                                                    </a>
                                                </Button>
                                                </TableCell>
                                                <TableCell>
                                                <IconButton
                                                    onClick={(event) => {
                                                        setAnchorEl(event.currentTarget);
                                                        setSelectedRow(row);
                                                    }}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                              
                                                <Menu
                                                    style={{ display: "flex", alignItems: "center" }}
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleMenuClose}
                                                >


                                                    <MenuItem onClick={handleDelete}>
                                                        Delete
                                                    </MenuItem>
                                                  
                                                </Menu>

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                        </TableBody>

                        {isUserNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        colSpan={6}
                                        sx={{ py: 3 }}
                                    >
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

        </Box >
    );
}

export default Question;